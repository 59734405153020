.menu-btn {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    align-items: center;
    justify-content: center;
    z-index: 10;

}

.menu-btn div {
    background-color: black;
    height: 0.2rem;
    width: 2.5rem;
    border-radius: 1rem;
    transition-duration: 0.6s;

}

.menu-btn.clicked {
    position: fixed;
    right: 0.75rem;
    top: 1.35rem;
}



.menu-btn.clicked>.stripe1 {
    transform: translateY(0.8rem) rotate(45deg);
}

.menu-btn.clicked>.stripe2 {
    transform: scaleX(0);
}

.menu-btn.clicked>.stripe3 {
    transform: translateY(-0.8rem) rotate(-45deg);

}

.menu-btn.clicked>div {
    background-color: var(--clr-secondary-200) !important;
}

#menu {
    position: fixed;
    top: 0;
    right: -100%;
    height: 100lvh;
    width: 100%;
    z-index: 5;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    transition-duration: 0.5s;
    background-color: rgba(255, 255, 255, 0.3);
}

#menu.show {
    right: 0;
}