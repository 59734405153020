@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Forum&family=Kantumruy+Pro:wght@300;400;600&family=Roboto+Mono:wght@100;400&family=Space+Mono&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;




/* MAIN */

:root {
  --clr-secondary-200: #3864FF;
}

.bg-white-transparent {
  background-color: rgba(255, 255, 255, 0.70);
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Forum', 'Times New Roman', Times, serif;
  padding-top: 5rem;
}

.bg-secondary {
  background-color: #F6F6F6;
}

img {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-user-drag: none;
}


/* BUTTONS */

.blue-button {
  background-color: var(--clr-secondary-200);
  color: white;
  border-radius: 2rem;
  font-size: 1.2rem;
  padding: 0 0.7rem;
  transition-duration: 0.5s;
}

.blue-button:hover {
  background-color: #234ad8;
}




/* UNDERLINES */

.clean-underline {
  position: relative;
}

.clean-underline::after {
  content: '';
  position: absolute;

  background-color: black;
  height: 1px;
  width: 60%;
  bottom: 0.1rem;
  left: 50%;
  transform: translateX(-50%);
  transition-duration: 0.5s;
}

.clean-underline:hover::after {
  width: 100%;
}

.clean-underline-l {
  position: relative;
}

.clean-underline-l::after {
  content: '';
  position: absolute;

  background-color: black;
  height: 1px;
  width: 60%;
  bottom: 0.1rem;
  left: 0;

  transition-duration: 0.5s;
}

.clean-underline-l:hover::after {
  width: 100%;
}

.link-hover {
  position: relative;
}

.link-hover::after {
  content: '';
  position: absolute;

  background-color: black;
  height: 1px;
  width: 0%;
  bottom: 0.1rem;
  left: 0;

  transition-duration: 0.5s;
}

.link-hover:hover::after {
  width: 100%;
}

/* HR */

hr {
  margin: 2rem auto;
  width: 70%;
  border-color: rgb(71, 71, 71);
}

/* MEDIA */


@media (max-width: 300px) {
  html {
    font-size: 12px;
  }
}

@media (min-width: 1024px) {
  hr {
    margin: 4rem auto;
  }

  .blue-button {
    font-size: 1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;

  }
}