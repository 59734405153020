#gallery-preview img {
    aspect-ratio: 1;
    object-fit: cover;

}

#gallery-preview {
    padding-top: 5em;
    margin-top: -5em;
}

.gallery-item {}